import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/admin",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "admindashboard",
          component: () => import("@/view/pages/admin/Dashboard.vue"),
        },
        {
          path: "businesses",
          name: "businesses",
          component: () => import("@/view/pages/admin/Businesses.vue"),
        },
        {
          path: "businesses/edit",
          name: "businesses.edit",
          component: () => import("@/view/pages/admin/Businesses-Edit.vue"),
        },
        {
          path: "reservation",
          component: () => import("@/view/pages/admin/Reservation.vue"),
        },
        {
          path: "categories",
          component: () => import("@/view/pages/admin/Categories.vue"),
        },
        {
          path: "dish-properties",
          component: () => import("@/view/pages/admin/DishPropeties.vue"),
        },
        {
          path: "dish-properties-group",
          component: () => import("@/view/pages/admin/PropertiesGroup.vue"),
        },
        {
          path: "toppings",
          component: () => import("@/view/pages/admin/Toppings.vue"),
        },
        {
          path: "topping-groups",
          component: () => import("@/view/pages/admin/ToppingGroups.vue"),
        },
        {
          path: "items",
          component: () => import("@/view/pages/admin/Items.vue"),
        },
        {
          path: "opening-times",
          component: () => import("@/view/pages/admin/OpeningTimes.vue"),
        },
        {
          path: "delivery-collection-timings",
          component: () => import("@/view/pages/admin/DeliveryCollectionTimings.vue"),
        },
        {
          path: "orders",
          component: () => import("@/view/pages/admin/Orders.vue"),
        },
        {
          path: "order-items",
          component: () => import("@/view/pages/admin/OrderItems.vue"),
        },
        {
          path: "vouchers",
          component: () => import("@/view/pages/admin/VoucherCodes.vue"),
        },
        {
          path: "user-vouchers",
          component: () => import("@/view/pages/admin/UserVoucherCodes.vue"),
        },
      ],
    },
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/orders",
          name: "orders",
          component: () => import("@/view/pages/error/Error.vue"),
          children: [
            {
              path: "today",
              name: "today",
              component: () => import("@/view/pages/orders/Today.vue"),
            },
            {
              path: "pending",
              name: "pending",
              component: () => import("@/view/pages/orders/Pending.vue"),
            },
            {
              path: "detail",
              name: "detail",
              props: true,
              component: () => import("@/view/pages/orders/OrderDetail.vue"),
            },
            {
              path: "history",
              name: "history",
              component: () => import("@/view/pages/orders/OrderHistory.vue"),
            },
            {
              path: "cancelled",
              name: "cancelled",
              component: () => import("@/view/pages/orders/OrderHistoryCancelled.vue"),
            },
            {
              path: "combined",
              name: "combined",
              component: () => import("@/view/pages/orders/OrderHistoryCombined.vue"),
            },
          ],
        },
        {
          path: "/menu-manager",
          name: "menu-manager",
          component: () => import("@/view/pages/error/Error.vue"),
          children: [
            {
              path: "categories",
              name: "categories",
              component: () => import("@/view/pages/menu/Category.vue"),
            },
            {
              path: "products",
              name: "products",
              component: () => import("@/view/pages/menu/Product.vue"),
            },
            {
              path: "toppings",
              name: "toppings",
              component: () => import("@/view/pages/menu/Topping.vue"),
            },
            {
              path: "properties",
              name: "properties",
              component: () => import("@/view/pages/menu/DishProperty.vue"),
            },
          ],
        },
        {
          path: "/system-setting",
          name: "system-setting",
          component: () => import("@/view/pages/error/Error.vue"),
          children: [
            {
              path: "contact-details",
              name: "wizard-contact-details",
              component: () => import("@/view/pages/business/components/ContactInfo.vue"),
            },
            {
              path: "restaurant-details",
              name: "wizard-restaurant-details",
              component: () => import("@/view/pages/business/components/RestaurantType.vue"),
            },
            {
              path: "menu-details",
              name: "wizard-menu-details",
              component: () => import("@/view/pages/business/components/Menu.vue"),
            },
            {
              path: "social-link-details",
              name: "wizard-social-link-details",
              component: () => import("@/view/pages/business/components/Social.vue"),
            },
            {
              path: "branding-details",
              name: "wizard-branding-details",
              component: () => import("@/view/pages/business/components/Branding.vue"),
            },
            {
              path: "opening-timings-details",
              name: "wizard-opening-timings-details",
              component: () => import("@/view/pages/business/components/OpeningTimes.vue"),
            },
            {
              path: "delivery-charges-details",
              name: "wizard-delivery-charges-details",
              component: () => import("@/view/pages/business/components/DeliveryCharge.vue"),
            },
            {
              path: "delivery-collection-details",
              name: "wizard-collection-details",
              component: () => import("@/view/pages/business/components/DeliveryCollectionTimeInfo.vue"),
            },
            {
              path: "domain",
              name: "domain",
              component: () => import("@/view/pages/business/Domain.vue"),
            },
            {
              path: "qrcode",
              name: "qrcode",
              component: () => import("@/view/pages/business/QrCode.vue"),
            },
            {
              path: "account-delete",
              name: "account-delete",
              component: () => import("@/view/pages/business/AccountDelete.vue"),
            },
          ],
        },
        {
          path: "/business",
          name: "business",
          component: () => import("@/view/pages/error/Error.vue"),
          children: [
            {
              path: "announcements",
              name: "announcements",
              component: () => import("@/view/pages/business/Announcements.vue"),
            },
            {
              path: "announcement",
              name: "announcement",
              component: () => import("@/view/pages/business/Announcement.vue"),
            },
            {
              path: "info",
              name: "info",
              component: () => import("@/view/pages/business/Info.vue"),
            },
            {
              path: "contact-us",
              name: "messages",
              component: () => import("@/view/pages/business/Messages.vue"),
            },
            {
              path: "customers",
              name: "users",
              component: () => import("@/view/pages/business/Users.vue"),
            },
            {
              path: "templateinfo",
              name: "templateinfo",
              component: () => import("@/view/pages/business/TemplateInfo.vue"),
            },
            {
              path: "reservations",
              name: "reservations",
              component: () => import("@/view/pages/business/Reservations.vue"),
            },
            {
              path: "reservation-settings",
              name: "reservation-settings",
              component: () => import("@/view/pages/business/components/settings/ReservationSettings.vue"),
            },
            
            {
              path: "delivery-collection",
              name: "delivery-collection",
              component: () => import("@/view/pages/business/DeliveryInfo.vue"),
            },
            {
              path: "opening-times",
              name: "opening-times",
              component: () => import("@/view/pages/business/OpeningTime.vue"),
            },
            {
              path: "wizard",
              name: "wizard",
              component: () => import("@/view/pages/business/Wizard.vue"),
            },
            {
              path: "settings",
              name: "settings",
              component: () => import("@/view/pages/business/Setting.vue"),
            },
            {
              path: "pos-import",
              name: "pos-import",
              component: () => import("@/view/pages/business/PosImport.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-new"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-new"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-new"),
        },
      ],
    },
    {
      path: "/business-claim/:token",
      component: () => import("@/view/pages/auth/login_pages/Claim-business")
    },
    {
      path: "/forgot-password",
      component: () => import("@/view/pages/auth/Forgot-password")
    },
    {
      path: "/reset-password/:token",
      component: () => import("@/view/pages/auth/Forgot-password-reset")
    },

    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
